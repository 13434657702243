<template>
  <poll-test
    v-if="moduleType && pollChain && poll"
    :poll-chain="pollChain"
    :poll="poll"
    :sending-status="sendingStatus"
    :module-type="moduleType"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { polls } from "@/store/modules/store.namespaces";
import {
  GET_COMMON_TEST_RESULTS,
  GET_TEST_RESULTS,
  GET_TESTS,
  RUN_TEST_POLL,
  SET_TEST_TIMER,
  SHOW_POLL_TEST_RESULT,
} from "@/store/modules/polls/action-types";
import { SET_SHOW_RESULT_AS_COMMON } from "@/store/modules/polls/mutation-types";
import { interactive } from "@/constants/polls/polls-module-types";
import { FORMATTED_TESTS, ONLY_TESTS } from "@/store/modules/polls/getter-types";
import BasePollContainer from "./components/BasePollContainer";
import PollTest from "./components/PollTest";

export default {
  name: "PollTestContainer",
  components: {
    PollTest,
  },
  extends: BasePollContainer,
  data() {
    return {
      prevFullscreenGroup: "",
    };
  },
  computed: {
    ...mapGetters(polls, {
      formattedTests: FORMATTED_TESTS,
      tests: ONLY_TESTS,
    }),
    pollChain() {
      return this.$store.state.polls[this.moduleType].chain;
    },
    poll() {
      return this.$store.state.polls[this.moduleType].activePoll;
    },
    sendingStatus() {
      return this.$store.state.polls[this.moduleType].sendingStatus;
    },
    /**
     * Проходимый пользователем тест
     */
    test() {
      return this.tests?.find(test => test.id === +this.testId);
    },
    previousRoute() {
      return this.$store.state.polls[this.moduleType].previousRoute || null;
    },
  },
  async created() {
    if (this.previousRoute && this.moduleType === interactive) {
      localStorage.setItem(
        "previousRoute",
        JSON.stringify({
          name: this.previousRoute.name,
          params: this.previousRoute.params,
        }),
      );
      if (
        this.$fullscreen.group === "presentation" &&
        this.$fullscreen.isFullscreen &&
        !this.$fullscreen.isEnabled
      ) {
        this.prevFullscreenGroup = this.$fullscreen.group;

        this.$fullscreen.exit(this.$fullscreen.group);
      }
    }
    if (!this.pollChain && !this.poll) {
      if (this.moduleType === interactive) {
        const previousRoute = JSON.parse(localStorage.getItem("previousRoute"));
        this.$router.push(previousRoute);
        return;
      }
      await this.getAllTests(this.$route.params.id);
      await this.handleRunTest();
    }
  },
  beforeDestroy() {
    localStorage.removeItem("previousRoute");
    if (this.prevFullscreenGroup && this.$isMobile && this.moduleType === interactive) {
      this.$nextTick(() => {
        this.$fullscreen.enter(this.prevFullscreenGroup);
      });
    }
  },
  methods: {
    ...mapActions(polls, {
      // setTestTimer: SET_TEST_TIMER,
      runTestPoll: RUN_TEST_POLL,
      getResults: GET_TEST_RESULTS,
      showTestResult: SHOW_POLL_TEST_RESULT,
      getCommonResults: GET_COMMON_TEST_RESULTS,
      getAllTests: GET_TESTS,
    }),
    ...mapMutations(polls, {
      setShowResultAsCommon: SET_SHOW_RESULT_AS_COMMON,
    }),
    preparePayload() {
      if (!this.test) return null;
      if (this?.test?.timer) {
        return {
          id: this.testId,
          title: this.test.title,
          timer: this.test.timer,
          // timerDelta: this.test.timerDelta,
          time_started: this.test.time_started
            ? this.test.time_started
            : Math.round(Date.now() / 1000),
          commonResult: this.test.commonResult,
          endless: this.test.endless_passing,
        };
      }
      return this.testId;
    },
    async handleRunTest() {
      if (
        this.test?.time_started &&
        +this.test?.timer &&
        !this.test?.endless_passing &&
        this.checkTestTime()
      ) {
        await this.handlerExpiredTest();
        return;
      }
      this.toggleShowResultAsCommon();
      await this.runTest();
    },
    checkTestTime() {
      return (
        +this.test.timer &&
        this.test.timer - (Math.round(Date.now() / 1000) - this.test.time_started) < 0
      );
    },
    toggleShowResultAsCommon() {
      if (this?.test?.commonResult) {
        this.setShowResultAsCommon({
          type: this.moduleType,
          payload: true,
        });
      } else {
        this.setShowResultAsCommon({
          type: this.moduleType,
          payload: false,
        });
      }
    },
    async handlerExpiredTest() {
      // await this.setTestTimer({ id: this.test.id, finish: true });
      setTimeout(() => {
        this.getTestResults(this.test.id);
      }, 500);
    },
    async getTestResults() {
      if (!this.test.commonResult) {
        this.getResults({
          type: this.moduleType,
          payload: this.testId,
        });
        await this.showTestResult({
          type: this.moduleType,
          testId: this.testId,
        });
      } else {
        await this.getCommonResults({
          type: this.moduleType,
          payload: this.testId,
        });
        this.showTestResult({
          type: this.moduleType,
          testId: this.testId,
          isCommon: true,
        });
      }
    },
    runTest() {
      const payload = this.preparePayload();
      if (!payload) return this.$router.push({ name: "presentation" });
      this.runTestPoll({
        type: this.moduleType,
        payload,
      });
    },
  },
};
</script>
